<template>
  <div class="basko-box-questions-container">
    <CategoryTitle :category="categoryQuestion" />

    <v-stepper flat v-model="currentStep">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
            :key="steps.indexOf(n)"
            :complete="currentStep > n"
            :step="steps.indexOf(n) + 1"
          >
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <h3>Com'è composta la tua famiglia?</h3>

          <v-row>
            <v-col
              cols="12"
              sm="4"
              v-for="question in questions[0].answers"
              :key="question.text"
            >
              <v-card flat class="warning lighten-1 h-100">
                <v-card-text>
                  <v-img :src="question.img"> </v-img>
                </v-card-text>
                <v-card-title>
                  {{ question.text }}
                </v-card-title>
                <v-card-actions>
                  <div class="qty-wrap not-empty w-100 white">
                    <v-btn
                      depressed
                      color="white"
                      small
                      class="minus quantity-btn"
                      @click.stop.prevent="minus(question)"
                    >
                      <v-icon>$minus</v-icon>
                    </v-btn>
                    <div class="val-cnt">
                      <span class="val">{{
                        question.number ? question.number : 0
                      }}</span>
                    </div>
                    <v-btn
                      class="plus quantity-btn"
                      color="white"
                      small
                      depressed
                      @click.stop.prevent="plus(question)"
                    >
                      <v-icon>$plus</v-icon>
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card></v-col
            >
          </v-row>

          <div class="d-flex justify-end mt-6">
            <v-btn large color="primary" outlined @click="goToPrev"
              >Indietro</v-btn
            >
            <v-btn
              large
              class="ml-3"
              color="primary"
              depressed
              @click="goToNext"
              >Prossima</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content class="pa-0" step="2">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              v-for="question in questions[1].answers"
              :key="question.text"
            >
              <v-card
                flat
                class="warning h-100"
                :class="{ ' lighten-1': !question.selected }"
              >
                <v-card-text class="d-flex justify-center">
                  <v-img contain width="100" height="100" :src="question.img">
                  </v-img>
                </v-card-text>
                <v-card-title>
                  {{ question.text }}
                </v-card-title>
                <v-card-subtitle>
                  {{ question.description }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    depressed
                    large
                    block
                    color="primary"
                    @click.stop.prevent="question.selected = !question.selected"
                  >
                    {{
                      question.selected !== true ? "SELEZIONA" : "DESELEZIONA"
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card></v-col
            >
          </v-row>
          <div class="d-flex justify-end mt-6">
            <v-btn large color="primary" outlined @click="goToPrev"
              >Indietro</v-btn
            >
            <v-btn
              large
              class="ml-3"
              color="primary"
              depressed
              @click="goToNext"
              >Prossima</v-btn
            >
          </div>
        </v-stepper-content>

        <v-stepper-content class="pa-0" step="3">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              v-for="question in questions[2].answers"
              :key="question.text"
            >
              <v-card
                flat
                class="warning h-100"
                :class="{ ' lighten-1': !question.selected }"
              >
                <v-card-text class="d-flex justify-center">
                  <v-img contain width="100" height="100" :src="question.img">
                  </v-img>
                </v-card-text>
                <v-card-title>
                  {{ question.text }}
                </v-card-title>
                <v-card-subtitle>
                  {{ question.description }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    depressed
                    large
                    block
                    color="primary"
                    @click.stop.prevent="question.selected = !question.selected"
                  >
                    {{
                      question.selected !== true ? "SELEZIONA" : "DESELEZIONA"
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card></v-col
            >
          </v-row>

          <div class="d-flex justify-end mt-6">
            <v-btn large color="primary" outlined @click="goToPrev"
              >Indietro</v-btn
            >
            <v-btn
              large
              class="ml-3"
              color="primary"
              depressed
              @click="goToNext"
              >Prossima</v-btn
            >

            <ResponseMessage class="mt-4" :response="responseMessage" />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<style lang="scss">
.basko-box-questions-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ResponseMessage from "@/components/ResponseMessage";

import recurrentOrderService from "@/commons/service/recurrentOrderService";

export default {
  name: "BaskoBoxQuestions",
  components: {
    CategoryTitle,
    ResponseMessage
  },
  props: {
    isRegistration: { type: String, default: "false" }
  },
  data() {
    return {
      loading: true,
      valid: true,
      responseMessage: {},
      currentStep: 1,
      steps: [{ name: 1 }, { name: 2 }, { name: 3 }],
      answersText: [],

      paramsSurvey: {
        IdTessera: "",
        IdQuestionario: "Q001",
        Questionario: [
          {
            Domanda: "Con chi vivi?",
            Risposta: ""
          },
          {
            Domanda: "Ti piacciono questi prodotti?",
            Risposta: ""
          },
          {
            Domanda: "Quale stile di vita di rappresenta maggiormente?",
            Risposta: ""
          }
        ]
      },

      categoryQuestion: {
        categoryId: 363541,
        name: "Conosciamoci meglio",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            HEADER_ICON: "",
            TITLE: "Conosciamoci meglio"
          }
        }
      },
      questions: [
        {
          id: 1,
          text: "Come è composta la tua famiglia?",
          answers: [
            {
              img: "/img_layout/eugenio_survey/adulti.svg",
              text: "Adulti",
              number: 0
            },
            {
              img: "/img_layout/eugenio_survey/bambini.svg",
              number: 0,
              text: "Bambini"
            },
            {
              img: "/img_layout/eugenio_survey/animali.svg",
              number: 0,
              text: "Animali"
            }
          ]
        },
        {
          id: 2,
          text: "Ti piacciono questi prodotti?",
          answers: [
            {
              selected: false,
              img: "/img_layout/eugenio_survey/biologico.svg",
              text: "Biologico",
              description:
                "Mi piace mangiare prodotti naturali che rispettano la natura"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/veg.svg",
              text: "Veg",
              description:
                "Seguo una dieta a base di alimenti solo di origine vegetale"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/gluten-free.svg",
              text: "Gluten free",
              description: "Sono intollerante al glutine o preferisco evitarlo"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/smile.svg",
              text: "Nessuna preferenza in particolare",
              description: "Mangio un po’ di tutto e non ho intolleranze"
            }
          ]
        },
        {
          id: 3,
          text: "Quale stile di vita ti rappresenta maggiormente?",
          answers: [
            {
              selected: false,
              img: "/img_layout/eugenio_survey/relax-e-natura.svg",
              text: "Relax e Natura",
              description: "Amo passeggiare all'aria aperta nei fine settimana"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/divertimento.svg",
              text: "Divertimento",
              description: "Mi piace divertirmi ed uscire con i miei amici"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/avventura.svg",
              text: "Avventura",
              description: "L'adrenalina e l'avventura sono parte di me"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/casa-e-famiglia.svg",
              text: "Casa e Famiglia",
              description:
                "Passare del tempo con le persone a me care è importante"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/sportivo.svg",
              text: "Sportivo",
              description:
                "Fermo non so stare! Attività fisica e sport: il mio stile di vita"
            },
            {
              selected: false,
              img: "/img_layout/eugenio_survey/chef.svg",
              text: "Chef per passione",
              description:
                "Cucinare piatti prelibati ed originali è la mia passione"
            }
          ]
        }
      ]
    };
  },
  computed: {
    consent() {
      return "Davanti alla porta;" + this.note1 + ";" + this.note2;
    }
  },
  methods: {
    toTop() {
      this.$nextTick(() => {
        this.$vuetify.goTo(0);
      });
    },
    goToPrev() {
      this.currentStep -= 1;
      this.toTop();
    },
    goToNext() {
      this.currentStep += 1;
      this.toTop();
      if (this.currentStep == 4) {
        this.sendSurvey();
      }
    },
    async sendSurvey() {
      this.paramsSurvey.Questionario[1].Risposta = this.questions[1].answers.reduce(
        (accumulator, item) =>
          item.selected === true
            ? (accumulator += item.text + ";")
            : (accumulator += ""),
        ""
      );

      this.paramsSurvey.Questionario[2].Risposta = this.questions[2].answers.reduce(
        (accumulator, item) =>
          item.selected === true
            ? (accumulator += item.text + ";")
            : (accumulator += ""),
        ""
      );

      let res = await recurrentOrderService.googleBoxSurvey(this.paramsSurvey);
      if (res && res.response && res.response.status == 0) {
        if (this.isRegistration) {
          this.$router.push({ name: "BaskoBoxPaymentMethod" });
        } else {
          this.$router.push({ name: "EugenioHome" });
        }
      } else if (res && res.response) {
        this.responseMessage = res.response;
      }
    },
    plus(answer) {
      answer.number = answer.number ? answer.number + 1 : 1;
    },
    minus(answer) {
      if (answer.number > 0) {
        answer.number -= 1;
      }
    }
  },
  async mounted() {}
};
</script>
